import { z } from "zod";
import { uuid } from "../../schema-validation/schemes";

export const Products = [
  "payroll",
  "i9-eVerify",
  "information-collection",
  "custom-paperwork",
] as const;

export const productDefinitionSchema = z.object({
  productDefinitionId: uuid(),
  product: z.enum(Products),
  description: z.string(),
});

export type ProductDefinitionSchema = z.infer<typeof productDefinitionSchema>;
