import { z } from "zod";
import { productDefinitionSchema } from "../../products";
import { uuid } from "../../schema-validation/schemes";
import { taskDefinitionTemplateSchema } from "./taskDefinitionTemplateSchema";

export const taskDefinitionSchema = z.object({
  taskDefinitionId: uuid(),
  productDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  companyID: uuid(),
  enabled: z.boolean().default(false),
});

export const taskDefinitionRefsPopulatedSchema = taskDefinitionSchema.extend({
  taskDefinition: taskDefinitionTemplateSchema.pick({
    requirements: true,
  }),
  productDefinition: productDefinitionSchema.pick({
    product: true,
    description: true,
  }),
  pendingTasks: z.number().default(0),
  completedTasks: z.number().default(0),
});

export type ZTaskDefinition = z.infer<typeof taskDefinitionSchema>;
export type ZTaskDefinitionRefsPopulated = z.infer<
  typeof taskDefinitionRefsPopulatedSchema
>;
