import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import {
  assertValidLaForgeSchema,
  LaForgeSchemaAssertionWithParams,
} from "./validation-types/assertValidLaForgeSchema";

import { assertPartnerCanAccessCompany } from "./routes/assertPartnerCanAccessCompany/assertPartnerCanAccessCompany";
import { company } from "./routes/company/company";
import { createCompanyLocation } from "./routes/createCompanyLocation/createCompanyLocation";
import { createContractor } from "./routes/createContractor/createContractor";
import { createContractorPayment } from "./routes/createContractorPayment/createContractorPayment";
import { createEmployee } from "./routes/createEmployee/createEmployee";
import { createEmployeeCheck } from "./routes/createEmployeeCheck/createEmployeeCheck";
import { createPayrollChecks } from "./routes/createPayrollChecks/createPayrollChecks";
import { createPayrollRun } from "./routes/createPayrollRun/createPayrollRun";
import { deleteCheck } from "./routes/deleteCheck/deleteCheck";
import { deleteMember } from "./routes/deleteMember/deleteMember";
import { deletePayrollRun } from "./routes/deletePayrollRun/deletePayrollRun";
import { deleteShift } from "./routes/deleteShift/deleteShift";
import { downloadCompanyTaxDocumentUrl } from "./routes/downloadCompanyTaxDocumentUrl/downloadCompanyTaxDocumentUrl";
import { downloadPoaDocument } from "./routes/downloadPoaDocument/downloadPoaDocument";
import { features } from "./routes/features/features";
import { finishHomeSetup } from "./routes/finishHomeSetup/finishHomeSetup";
import { generateDocumentGetUrl } from "./routes/generateDocumentGetUrl/generateDocumentGetUrl";
import { generateIdGetUrl } from "./routes/generateIdGetUrl/generateIdGetUrl";
import { generateLogoGetUrl } from "./routes/generateLogoGetUrl/generateLogoGetUrl";
import { generateLogoPutUrl } from "./routes/generateLogoPutUrl/generateLogoPutUrl";
import { getAdminInfo } from "./routes/getAdminInfo/getAdminInfo";
import { getCheckDates } from "./routes/getCheckDates/getCheckDates";
import { getCheckDatesReportingDetails } from "./routes/getCheckDatesReportingDetails/getCheckDatesReportingDetails";
import { getCheckPreview } from "./routes/getCheckPreview/getCheckPreview";
import { getChecksExistAlert } from "./routes/getChecksExistAlert/getChecksExistAlert";
import { getClosestCheckDate } from "./routes/getClosestCheckDate/getClosestCheckDate";
import { getCompanyDebits } from "./routes/getCompanyDebits/getCompanyDebits";
import { getCompanyLocations } from "./routes/getCompanyLocations/getCompanyLocations";
import { getCompanyTaxDocument } from "./routes/getCompanyTaxDocument/getCompanyTaxDocument";
import { getCompanyTaxDocuments } from "./routes/getCompanyTaxDocuments/getCompanyTaxDocuments";
import { getCompanyTaxRequirements } from "./routes/getCompanyTaxRequirements/getCompanyTaxRequirements";
import { getCompanyVeriffToken } from "./routes/getCompanyVeriffToken/getCompanyVeriffToken";
import { getContractorInfo } from "./routes/getContractorInfo/getContractorInfo";
import { getContractorInfoByPayload } from "./routes/getContractorInfoByPayload/getContractorInfoByPayload";
import { getContractorPayment } from "./routes/getContractorPayment/getContractorPayment";
import { getContractorPayments } from "./routes/getContractorPayments/getContractorPayments";
import { getContractors } from "./routes/getContractors/getContractors";
import { getCurrentUser } from "./routes/getCurrentUser/getCurrentUser";
import { getDocuments } from "./routes/getDocuments/getDocuments";
import { getEmployeeCheck } from "./routes/getEmployeeCheck/getEmployeeCheck";
import { getEmployeeCheckDetails } from "./routes/getEmployeeCheckDetails/getEmployeeCheckDetails";
import { getEmployeeCheckInfo } from "./routes/getEmployeeCheckInfo/getEmployeeCheckInfo";
import { getEmployeeChecks } from "./routes/getEmployeeChecks/getEmployeeChecks";
import { getEmployeeChecksByPayload } from "./routes/getEmployeeChecksByPayload/getEmployeeChecksByPayload";
import { getEmployeeReportingPeriods } from "./routes/getEmployeeReportingPeriods/getEmployeeReportingPeriods";
import { getFaxes } from "./routes/getFaxes/getFaxes";
import { getGovernmentIdUrls } from "./routes/getGovernmentIdUrls/getGovernmentIdUrls";
import { getGuestInfo } from "./routes/getGuestInfo/getGuestInfo";
import { getInternalUserInfo } from "./routes/getInternalUserInfo/getInternalUserInfo";
import { getMembers } from "./routes/getMembers/getMembers";
import { getMyContractorPayments } from "./routes/getMyContractorPayments/getMyContractorPayments";
import { getMyEmployerChecks } from "./routes/getMyEmployerChecks/getMyEmployerChecks";
import { getMyRecentlyOnboardedContractors } from "./routes/getMyRecentlyOnboardedContractors/getMyRecentlyOnboardedContractors";
import { getMyRecentlyOnboardedWorkers } from "./routes/getMyRecentlyOnboardedWorkers/getMyRecentlyOnboardedWorkers";
import { getMyWorkers } from "./routes/getMyWorkers/getMyWorkers";
import { getOnboardingAuthLink } from "./routes/getOnboardingAuthLink/getOnboardingAuthLink";
import { getOnboardingLink } from "./routes/getOnboardingLink/getOnboardingLink";
import { getPayrollChecks } from "./routes/getPayrollChecks/getPayrollChecks";
import { getPayrollRuns } from "./routes/getPayrollRuns/getPayrollRuns";
import { getPendingShifts } from "./routes/getPendingShifts/getPendingShifts";
import { getPoaDocuments } from "./routes/getPoaDocuments/getPoaDocuments";
import { getReportingPeriodsByFrequency } from "./routes/getReportingPeriodsByFrequency/getReportingPeriodsByFrequency";
import { getReportsStatus } from "./routes/getReportsStatus/getReportsStatus";
import { getSecondaryJWT } from "./routes/getSecondaryJWT/getSecondaryJWT";
import { getSubEmployeeChecks } from "./routes/getSubEmployeeChecks/getSubEmployeeChecks";
import { getUpcomingChecks } from "./routes/getUpcomingChecks/getUpcomingChecks";
import { getUserInfo } from "./routes/getUserInfo/getUserInfo";
import { getWLAlerts } from "./routes/getWLAlerts/getWLAlerts";
import { getWLHostedConfig } from "./routes/getWLHostedConfig/getWLHostedConfig";
import { getWLInfo } from "./routes/getWLInfo/getWLInfo";
import { getWLInfoById } from "./routes/getWLInfoById/getWLInfoById";
import { getWorkerInfo } from "./routes/getWorkerInfo/getWorkerInfo";
import { getWorkerInfoByPayload } from "./routes/getWorkerInfoByPayload/getWorkerInfoByPayload";
import { inviteMember } from "./routes/inviteMember/inviteMember";
import { login } from "./routes/login/login";
import { logout } from "./routes/logout/logout";
import { postCashRequirements } from "./routes/postCashRequirements/postCashRequirements";
import { postCustomPayrollJournal } from "./routes/postCustomPayrollJournal/postCustomPayrollJournal";
import { postGeneratePaystubFile } from "./routes/postGeneratePaystubFile/postGeneratePaystubFile";
import { postLaborAllocation } from "./routes/postLaborAllocation/postLaborAllocation";
import { postPaymentSummary } from "./routes/postPaymentSummary/postPaymentSummary";
import { postPayrollJournal } from "./routes/postPayrollJournal/postPayrollJournal";
import { postPaystub } from "./routes/postPaystub/postPaystub";
import { postPreviewCheckData } from "./routes/postPreviewCheckData/postPreviewCheckData";
import { postWorkerSummary } from "./routes/postWorkerSummary/postWorkerSummary";
import { resendFax } from "./routes/resendFax/resendFax";
import { rollKey } from "./routes/rollKey/rollKey";
import { sendContractorOnboardingLink } from "./routes/sendContractorOnboardingLink/sendContractorOnboardingLink";
import { sendOnboardingLink } from "./routes/sendOnboardingLink/sendOnboardingLink";
import { setFaviconStatus } from "./routes/setFaviconStatus/setFaviconStatus";
import { setLogoStatus } from "./routes/setLogoStatus/setLogoStatus";
import { setUserPayrollRunSettings } from "./routes/setUserPayrollRunSettings/setUserPayrollRunSettings";
import { submitPayrollRun } from "./routes/submitPayrollRun/submitPayroll";
import { submitShift } from "./routes/submitShift/submitShift";
import { testCheckCreatedWebhook } from "./routes/testCheckCreatedWebhook/testCheckCreatedWebhook";
import { testCheckProcessedLeanWebhook } from "./routes/testCheckProcessedLeanWebhook/testCheckProcessedLeanWebhook";
import { testCheckProcessedWebhook } from "./routes/testCheckProcessedWebhook/testCheckProcessedWebhook";
import { testContractorPaymentWebhook } from "./routes/testContractorPaymentWebhook/testContractorPaymentWebhook";
import { testContractorWebhook } from "./routes/testContractorWebhook/testContractorWebhook";
import { testCreationWebhook } from "./routes/testCreationWebhook/testCreationWebhook";
import { testEmployeeCheckWebhook } from "./routes/testEmployeeCheckWebhook/testEmployeeCheckWebhook";
import { testEmployeeWebhook } from "./routes/testEmployeeWebhook/testEmployeeWebhook";
import { testEmployerOnboardingWebhook } from "./routes/testEmployerOnboardingWebhook/testEmployerOnboardingWebhook";
import { testOnboardingWebhook } from "./routes/testOnboardingWebhook/testOnboardingWebhook";
import { testReportWebhook } from "./routes/testReportWebhook/testReportWebhook";
import { testShiftWebhook } from "./routes/testShiftWebhook/testShiftWebhook";
import { token } from "./routes/token/token";
import { UNSAFE__forceExpireCookies } from "./routes/UNSAFE__forceExpireCookies/UNSAFE__forceExpireCookies";
import { updateAdminInfo } from "./routes/updateAdminInfo/updateAdminInfo";
import { updateCheck } from "./routes/updateCheck/updateCheck";
import { updateCheckCreatedWebhook } from "./routes/updateCheckCreatedWebhook/updateCheckCreatedWebhook";
import { updateCheckProcessedLeanWebhook } from "./routes/updateCheckProcessedLeanWebhook/updateCheckProcessedLeanWebhook";
import { updateCheckProcessedWebhook } from "./routes/updateCheckProcessedWebhook/updateCheckProcessedWebhook";
import { updateCompanyLocation } from "./routes/updateCompanyLocation/updateCompanyLocation";
import { updateCompanyTaxDocumentById } from "./routes/updateCompanyTaxDocumentById/updateCompanyTaxDocumentById";
import { updateCompanyVeriffToken } from "./routes/updateCompanyVeriffToken/updateCompanyVeriffToken";
import { updateContractor } from "./routes/updateContractor/updateContractor";
import { updateContractorPaymentWebhook } from "./routes/updateContractorPaymentWebhook/updateContractorPaymentWebhook";
import { updateContractorWebhook } from "./routes/updateContractorWebhook/updateContractorWebhook";
import { updateCreationWebhook } from "./routes/updateCreationWebhook/updateCreationWebhook";
import { updateEmployeeAsCompany } from "./routes/updateEmployeeAsCompany/updateEmployeeAsCompany";
import { updateEmployeeCheckByID } from "./routes/updateEmployeeCheckByID/updateEmployeeCheckByID";
import { updateEmployeeCheckWebhook } from "./routes/updateEmployeeCheckWebhook/updateEmployeeCheckWebhook";
import { updateEmployeeWebhook } from "./routes/updateEmployeeWebhook/updateEmployeeWebhook";
import { updateEmployerOnboardingWebhook } from "./routes/updateEmployerOnboardingWebhook/updateEmployerOnboardingWebhook";
import { updateGuestInfo } from "./routes/updateGuestInfo /updateGuestInfo";
import { updateOnboardingWebhook } from "./routes/updateOnboardingWebhook/updateOnboardingWebhook";
import { updatePayrollRun } from "./routes/updatePayrollRun/updatePayrollRun";
import { updateReportWebhook } from "./routes/updateReportWebhook/updateReportWebhook";
import { updateShift } from "./routes/updateShift/updateShift";
import { updateShiftWebhook } from "./routes/updateShiftWebhook/updateShiftWebhook";
import { uploadTaxParameters } from "./routes/updateTaxParameters/updateTaxParameters";
import { updateUserInfo } from "./routes/updateUserInfo/updateUserInfo";
import { updateWLInfo } from "./routes/updateWLInfo/updateWLInfo";
import { uploadIDVerification } from "./routes/uploadIDVerification/uploadIDVerification";
import { updateImageAws } from "./routes/uploadImageAws/uploadImageAws";
import { createVeriffSession } from "./routes/veriff/createVeriffSession";
import { whoami } from "./routes/whoami/whoami";

import { approveCheck } from "./routes/approveCheck/approveCheck";
import { checkCookie } from "./routes/checkCookie/checkCookie";
import { completeEmployerOnboardingFlow } from "./routes/completeEmployerOnboardingFlow/completeEmployerOnboardingFlow";
import { createAccount } from "./routes/createAccount/createAccount";
import { createBankAccount } from "./routes/createBankAccount/createBankAccount";
import { createCompanyTaxDocument } from "./routes/createCompanyTaxDocument/createCompanyTaxDocument";
import { createContractorPayCard } from "./routes/createContractorPayCard/createContractorPayCard";
import { createCustomPaperwork } from "./routes/createCustomPaperwork/createCustomPaperwork";
import { createEmployeePayCard } from "./routes/createEmployeePayCard/createEmployeePayCard";
import { createFundingSource } from "./routes/createFundingSource/createFundingSource";
import {
  archivePaperworkTemplate,
  getPaperworkTemplate,
  patchPaperworkTemplate,
  postPaperworkTemplate,
  putPaperworkTemplate,
} from "./routes/CustomPaperwork";
import { downloadEVerifyNotice } from "./routes/downloadEVerifyNotice/downloadEVerifyNotice";
import { downloadGarnishmentDocument } from "./routes/downloadGarnishmentDocument/downloadGarnishmentDocument";
import { fetchCheckDetails } from "./routes/fetchCheckDetails/fetchCheckDetails";
import { fetchCustomPaperwork } from "./routes/fetchCustomPaperwork/fetchCustomPaperwork";
import { generateFileGetUrl } from "./routes/generateFileGetUrl/generateFileGetUrl";
import { generateFilePutUrl } from "./routes/generateFilePutUrl/generateFilePutUrl";
import { generateFilesPutUrl } from "./routes/generateFilesPutUrl/generateFilesPutUrl";
import { getActivePayrollRuns } from "./routes/getActivePayrollRuns/getActivePayrollRuns";
import { getAuthorizedRepresentativeProgress } from "./routes/getAuthorizedRepresentativeProgress/getAuthorizedRepresentativeProgress";
import { getBulkEVerifyPublicCaseStatuses } from "./routes/getBulkEVerifyPublicCaseStatuses/getBulkEVerifyPublicCaseStatuses";
import { getCheckCsvTemplate } from "./routes/getCheckCsvTemplate/getCheckCsvTemplate";
import { getCheckPDF } from "./routes/getCheckPDF/getCheckPDF";
import { getCompanyKybDocuments } from "./routes/getCompanyKybDocuments/getCompanyKybDocuments";
import { getContractorPayCards } from "./routes/getContractorPayCards/getContractorPayCards";
import { getCurrentUserAccounts } from "./routes/getCurrentUserAccounts/getCurrentUserAccounts";
import { getCustomerAccountOnboardingInfo } from "./routes/getCustomerAccountOnboardingInfo/getCustomerAccountOnboardingInfo";
import { getCustomerAccounts } from "./routes/getCustomerAccounts/getCustomerAccounts";
import { getCustomPaperworkTemplates } from "./routes/getCustomPaperworkTemplates/getCustomPaperworkTemplates";
import { getDoesUserExist } from "./routes/getDoesUserExist/getDoesUserExist";
import { getEmbeddingSignedToken } from "./routes/getEmbeddingSignedToken/getEmbeddingSignedToken";
import { getEmployeeCheckDeductions } from "./routes/getEmployeeCheckDeductions/getEmployeeCheckDeductions";
import { getEmployeePayCards } from "./routes/getEmployeePayCards/getEmployeePayCards";
import { getEmployeeRequirementsLinks } from "./routes/getEmployeeRequirementLinks/getEmployeeRequirementsLinks";
import { getEmployerCheckById } from "./routes/getEmployerCheckById/getEmployerCheckById";
import { getEmployerCheckInternal } from "./routes/getEmployerCheckInternal/getEmployerCheckInternal";
import { getFormList } from "./routes/getFormList/getFormList";
import { getFormSubmissions } from "./routes/getFormSubmissions/getFormSubmissions";
import { getFormTemplate } from "./routes/getFormTemplate/getFormTemplate";
import { getI9OnboardingInfo } from "./routes/getI9OnboardingInfo/getI9OnboardingInfo";
import { getInfoForAuthorizedRepresentative } from "./routes/getInfoForAuthorizedRepresentative/getInfoForAuthorizedRepresentative";
import { getJobs } from "./routes/getJobs/getJobs";
import { getKYC } from "./routes/getKYC/getKYC";
import { getLocalTaxTypes } from "./routes/getLocalTaxTypes/getLocalTaxTypes";
import { getNextRegularPayrollRun } from "./routes/getNextRegularPayrollRun/getNextRegularPayrollRun";
import { getNextRegularRun } from "./routes/getNextRegularRun/getNextRegularRun";
import { getPayCard } from "./routes/getPayCard/getPayCard";
import { getPayCardArt } from "./routes/getPayCardArt/getPayCardArt";
import { getPayCardToken } from "./routes/getPayCardToken/getPayCardToken";
import { getPayCardTransactions } from "./routes/getPayCardTransactions/getPayCardTransactions";
import { getPayrollRunById } from "./routes/getPayrollRunById/getPayrollRunById";
import { getPayrollRunEmployees } from "./routes/getPayrollRunEmployees/getPayrollRunEmployees";
import { getPendingEVerifyActionByAdmin } from "./routes/getPendingEVerifyActionByAdmin/getPendingEVerifyActionByAdmin";
import { getQuickviewContractorInfo } from "./routes/getQuickviewContractorInfo/getQuickviewContractorInfo";
import { getQuickviewWorkerInfo } from "./routes/getQuickviewWorkerInfo/getQuickviewWorkerInfo";
import { getStateTaxConfig } from "./routes/getStateTaxConfig/getStateTaxConfig";
import { getTaxSetupInfo } from "./routes/getTaxSetupInfo/getTaxSetupInfo";
import { getTransactionEvents } from "./routes/getTransactionEvents/getTransactionEvents";
import { getVeriffIds } from "./routes/getVeriffIds/getVeriffIds";
import { getWageViolations } from "./routes/getWageViolations/getWageViolations";
import { getWorkerCount } from "./routes/getWorkerCount/getWorkerCount";
import { patchTaxSetupInfo } from "./routes/patchTaxSetupInfo/patchTaxSetupInfo";
import { migrateEmployeeData } from "./routes/payrollMigration/migrateEmployeeData";
import { migrateEmployeeTaxParamsData } from "./routes/payrollMigration/migrateEmployeeTaxParamsData";
import { postAddPayrollRunChecks } from "./routes/postAddPayrollRunChecks/postAddPayrollRunChecks";
import { postAttachEVerifyDocumentToWorker } from "./routes/postAttachEVerifyDocumentToWorker/postAttachEVerifyDocumentToWorker";
import { postCompanyDebits } from "./routes/postCompanyDebits/postCompanyDebits";
import { postCreatePayrollRun } from "./routes/postCreatePayrollRun/postCreatePayrollRun";
import { postDeductionsSummary } from "./routes/postDeductionsSummary/postDeductionsSummary";
import { postEmployees } from "./routes/postEmployees/postEmployees";
import { postForm } from "./routes/postForm/postForm";
import { postFormRenderJSON } from "./routes/postFormRenderJSON/postFormRenderJSON";
import { postFormRenderPDF } from "./routes/postFormRenderPDF/postFormRenderPDF";
import { postGarnishmentDocument } from "./routes/postGarnishmentDocument/postGarnishmentDocument";
import { postKycSummary } from "./routes/postKycSummary/postKycSummary";
import { postManageBulkDeductions } from "./routes/postManageBulkDeductions/postBulkManageDeductions";
import { postParseChecksCSV } from "./routes/postParseChecksCSV/postParseChecksCSV";
import { postResolveEVerifyFinalNonConfirmation } from "./routes/postResolveEVerifyFinalNonconfirmation/postResolveEVerifyFinalNonconfirmation";
import { postResolveEVerifyPendingReferral } from "./routes/postResolveEVerifyPendingReferral/postResolveEVerifyPendingReferral";
import { postTelemetry } from "./routes/postTelemetry/postTelemetry";
import { verifyMicroDeposits } from "./routes/postVerifyMicroDeposits/postVerifyMicroDeposits";
import { removeAccountMembership } from "./routes/removeAccountMembership/removeAccountMembership";
import { replaceI9AuthRepLocation } from "./routes/replaceI9Location/replaceI9AuthRepLocation";
import { replaceI9WorkerLocation } from "./routes/replaceI9Location/replaceI9WorkerLocation";
import { saveCompanySignature } from "./routes/saveCompanySignature/saveCompanySignature";
import { saveEmployerOnboardingTerms } from "./routes/saveEmployerOnboardingTerms/saveEmployerOnboardingTerms";
import { saveStateTaxParams } from "./routes/saveStateTaxParams/saveStateTaxParams";
import { selectAccount } from "./routes/selectAccount/selectAccount";
import { submitEmailConfirmation } from "./routes/submitEmailConfirmation/submitEmailConfirmation";
import { testEmployeeRequirementCreatedWebhook } from "./routes/testEmployeeRequirementCreatedWebhook/testEmployeeRequirementCreatedWebhook";
import { testEmployeeRequirementUpdatedWebhook } from "./routes/testEmployeeRequirementUpdatedWebhook/testEmployeeRequirementUpdatedWebhook";
import { testEVerifyWebhook } from "./routes/testEVerifyWebhook/testEVerifyWebhook";
import { testI9Webhook } from "./routes/testI9Webhook/testI9Webhook";
import { updateBankAccount } from "./routes/updateBankAccount/updateBankAccount";
import { updateCompany } from "./routes/updateCompany/updateCompany";
import { updateCustomerAccount } from "./routes/updateCustomerAccount/updateCustomerAccount";
import { updateCustomerAccountInfo } from "./routes/updateCustomerAccountInfo/updateCustomerAccountInfo";
import { updateCustomPaperwork } from "./routes/updateCustomPaperwork/updateCustomPaperwork";
import { updateEmployeeAsSelf } from "./routes/updateEmployeeAsSelf/updateEmployeeAsSelf";
import { updateEmployeeRequirementCreatedWebhook } from "./routes/updateEmployeeRequirementCreatedWebhook/updateEmployeeRequirementCreatedWebhook";
import { updateEmployeeRequirementUpdatedWebhook } from "./routes/updateEmployeeRequirementUpdatedWebhook/updateEmployeeRequirementUpdatedWebhook";
import { updateEVerifyPublicCaseStatus } from "./routes/updateEVerifyPublicCaseStatus/updateEVerifyPublicCaseStatus";
import { updateEVerifyWebhook } from "./routes/updateEVerifyWebhook/updateEVerifyWebhook";
import { updateI9OnboardingInfo } from "./routes/updateI9OnboardingInfo/updateI9OnboardingInfo";
import { updateI9OnboardingInfoAsAuthorizedRepresentative } from "./routes/updateI9OnboardingInfoAsAuthorizedRepresentative/updateI9OnboardingInfoAsAuthorizedRepresentative";
import { updateI9Webhook } from "./routes/updateI9Webhook/updateI9Webhook";
import { updatePartner } from "./routes/updatePartner/updatePartner";
import { updateFileAws } from "./routes/uploadFileAws/uploadFileAws";
import { validateOnboardingToken } from "./routes/validateOnboardingToken/validateOnboardingToken";
import { verifyRoutingNumber } from "./routes/verifyRoutingNumber/verifyRoutingNumber";

export class LaForgeClient extends AbstractApiClient {
  public static AssertValidSchema: LaForgeSchemaAssertionWithParams =
    assertValidLaForgeSchema;

  public readonly ApproveCheck = approveCheck(this.Instance);
  public readonly AssertPartnerCanAccessCompany = assertPartnerCanAccessCompany(
    this.Instance
  );
  public readonly getCurrentUserAccounts = getCurrentUserAccounts(
    this.Instance
  );
  public readonly getDoesUserExist = getDoesUserExist(this.Instance);
  public readonly createAccount = createAccount(this.Instance);
  public readonly selectAccount = selectAccount(this.Instance);
  public readonly submitEmailConfirmation = submitEmailConfirmation(
    this.Instance
  );
  public readonly CheckCookie = checkCookie(this.Instance);
  public readonly Company = company(this.Instance);
  public readonly CompleteEmployerOnboardingFlow =
    completeEmployerOnboardingFlow(this.Instance);
  public readonly CreateCompanyTaxDocument = createCompanyTaxDocument(
    this.Instance
  );
  public readonly CreateContractor = createContractor(this.Instance);
  public readonly CreateContractorPayCard = createContractorPayCard(
    this.Instance
  );
  public readonly CreateContractorPayment = createContractorPayment(
    this.Instance
  );
  public readonly CreateEmployee = createEmployee(this.Instance);
  public readonly CreateEmployeePayCard = createEmployeePayCard(this.Instance);
  public readonly CreateVeriffSession = createVeriffSession(this.Instance);
  public readonly DeleteCheck = deleteCheck(this.Instance);
  public readonly DeleteMember = deleteMember(this.Instance);
  public readonly DownloadCompanyTaxDocumentUrl = downloadCompanyTaxDocumentUrl(
    this.Instance
  );
  public readonly DownloadPoaDocument = downloadPoaDocument(this.Instance);
  public readonly Features = features(this.Instance);
  public readonly FinishHomeSetup = finishHomeSetup(this.Instance);
  public readonly GenerateDocumentGetUrl = generateDocumentGetUrl(
    this.Instance
  );
  public readonly GenerateIdGetUrl = generateIdGetUrl(this.Instance);
  public readonly GenerateLogoGetUrl = generateLogoGetUrl(this.Instance);
  public readonly GenerateLogoPutUrl = generateLogoPutUrl(this.Instance);
  public readonly GetActivePayrollRuns = getActivePayrollRuns(this.Instance);
  public readonly GetNextRegularRun = getNextRegularRun(this.Instance);
  public readonly GetAdminInfo = getAdminInfo(this.Instance);
  public readonly GetCheckCsvTemplate = getCheckCsvTemplate(this.Instance);
  public readonly GetCheckDates = getCheckDates(this.Instance);
  public readonly GetCheckDatesReportingDetails = getCheckDatesReportingDetails(
    this.Instance
  );
  public readonly GetCompanyDebits = getCompanyDebits(this.Instance);
  public readonly GetCompanyTaxDocument = getCompanyTaxDocument(this.Instance);
  public readonly GetCompanyTaxDocuments = getCompanyTaxDocuments(
    this.Instance
  );
  public readonly GetCompanyTaxRequirements = getCompanyTaxRequirements(
    this.Instance
  );
  public readonly GetContractorInfo = getContractorInfo(this.Instance);
  public readonly GetQuickviewContractorInfo = getQuickviewContractorInfo(
    this.Instance
  );
  public readonly GetContractorInfoByPayload = getContractorInfoByPayload(
    this.Instance
  );
  public readonly GetContractorPayCards = getContractorPayCards(this.Instance);
  public readonly GetContractorPayment = getContractorPayment(this.Instance);
  public readonly GetContractorPayments = getContractorPayments(this.Instance);
  public readonly GetCustomerAccounts = getCustomerAccounts(this.Instance);
  public readonly GetContractors = getContractors(this.Instance);
  public readonly GetDocuments = getDocuments(this.Instance);
  public readonly GetCurrentUser = getCurrentUser(this.Instance);
  public readonly GetEmployeeCheck = getEmployeeCheck(this.Instance);
  public readonly GetEmployeeCheckDetails = getEmployeeCheckDetails(
    this.Instance
  );
  public readonly GetEmployeeCheckInfo = getEmployeeCheckInfo(this.Instance);
  public readonly GetEmployeeChecks = getEmployeeChecks(this.Instance);
  public readonly GetEmployeeCheckDeductions = getEmployeeCheckDeductions(
    this.Instance
  );
  public readonly GetEmployeeChecksByPayload = getEmployeeChecksByPayload(
    this.Instance
  );
  public readonly GetEmployeePayCards = getEmployeePayCards(this.Instance);
  public readonly GetEmployeeReportingPeriods = getEmployeeReportingPeriods(
    this.Instance
  );
  public readonly GetEmployerCheckById = getEmployerCheckById(this.Instance);
  public readonly GetEmployerCheckInternal = getEmployerCheckInternal(
    this.Instance
  );
  public readonly GetGovernmentIdUrls = getGovernmentIdUrls(this.Instance);
  public readonly GetGuestInfo = getGuestInfo(this.Instance);
  public readonly GetInternalUserInfo = getInternalUserInfo(this.Instance);
  public readonly GetJobs = getJobs(this.Instance);
  public readonly GetKYC = getKYC(this.Instance);
  public readonly GetMembers = getMembers(this.Instance);
  public readonly GetMyEmployerChecks = getMyEmployerChecks(this.Instance);
  public readonly GetMyContractorPayments = getMyContractorPayments(
    this.Instance
  );
  public readonly GetMyWorkers = getMyWorkers(this.Instance);
  public readonly GetMyRecentlyOnboardedContractors =
    getMyRecentlyOnboardedContractors(this.Instance);
  public readonly GetMyRecentlyOnboardedWorkers = getMyRecentlyOnboardedWorkers(
    this.Instance
  );
  public readonly GetNextRegularPayrollRun = getNextRegularPayrollRun(
    this.Instance
  );
  public readonly GetOnboardingAuthLink = getOnboardingAuthLink(this.Instance);
  public readonly GetOnboardingLink = getOnboardingLink(this.Instance);
  public readonly GetPayCard = getPayCard(this.Instance);
  public readonly GetPayCardToken = getPayCardToken(this.Instance);
  public readonly GetPayCardTransactions = getPayCardTransactions(
    this.Instance
  );
  public readonly GetPayCardArt = getPayCardArt(this.Instance);
  public readonly GetPendingShifts = getPendingShifts(this.Instance);
  public readonly GetPoaDocuments = getPoaDocuments(this.Instance);
  public readonly GetReportsStatus = getReportsStatus(this.Instance);
  public readonly GetSecondaryJWT = getSecondaryJWT(this.Instance);
  public readonly GetStateTaxConfig = getStateTaxConfig(this.Instance);
  public readonly GetLocalTaxTypes = getLocalTaxTypes(this.Instance);
  public readonly GetSubEmployeeChecks = getSubEmployeeChecks(this.Instance);
  public readonly GetTaxSetupInfo = getTaxSetupInfo(this.Instance);
  public readonly GetUpcomingCheck = getUpcomingChecks(this.Instance);
  public readonly GetUserInfo = getUserInfo(this.Instance);
  public readonly GetVeriffIds = getVeriffIds(this.Instance);
  public readonly GetWLAlerts = getWLAlerts(this.Instance);
  public readonly GetWLHostedConfig = getWLHostedConfig(this.Instance);
  public readonly GetWLInfo = getWLInfo(this.Instance);
  public readonly GetWLInfoById = getWLInfoById(this.Instance);
  public readonly GetWorkerInfoByPayload = getWorkerInfoByPayload(
    this.Instance
  );
  public readonly GetWorkerInfo = getWorkerInfo(this.Instance);
  public readonly GetQuickviewWorkerInfo = getQuickviewWorkerInfo(
    this.Instance
  );

  public readonly InviteMember = inviteMember(this.Instance);
  public readonly Login = login(this.Instance);
  public readonly Logout = logout(this.Instance);
  public readonly PatchTaxSetupInfo = patchTaxSetupInfo(this.Instance);
  public readonly PostAddPayrollRunChecks = postAddPayrollRunChecks(
    this.Instance
  );
  public readonly PostCashRequirements = postCashRequirements(this.Instance);
  public readonly PostCompanyDebits = postCompanyDebits(this.Instance);
  public readonly PostCreatePayrollRun = postCreatePayrollRun(this.Instance);
  public readonly PostCustomPayrollJournal = postCustomPayrollJournal(
    this.Instance
  );
  public readonly GetCheckPDF = getCheckPDF(this.Instance);
  public readonly PostGeneratePaystubFile = postGeneratePaystubFile(
    this.Instance
  );
  public readonly PostGarnishmentDocument = postGarnishmentDocument(
    this.Instance
  );
  public readonly DownloadGarnishmentDocument = downloadGarnishmentDocument(
    this.Instance
  );
  public readonly PostDeductionsSummary = postDeductionsSummary(this.Instance);
  public readonly PostEmployees = postEmployees(this.Instance);
  public readonly PostKycSummary = postKycSummary(this.Instance);
  public readonly PostLaborAllocation = postLaborAllocation(this.Instance);
  public readonly PostPaymentSummary = postPaymentSummary(this.Instance);
  public readonly PostPayrollJournal = postPayrollJournal(this.Instance);
  public readonly PostPaystub = postPaystub(this.Instance);
  public readonly PostPreviewCheckData = postPreviewCheckData(this.Instance);
  public readonly PostWorkerSummary = postWorkerSummary(this.Instance);
  public readonly ReplaceI9WorkerLocation = replaceI9WorkerLocation(
    this.Instance
  );
  public readonly ReplaceI9AuthRepLocation = replaceI9AuthRepLocation(
    this.Instance
  );
  public readonly RollKey = rollKey(this.Instance);
  public readonly SaveEmployerOnboardingTerms = saveEmployerOnboardingTerms(
    this.Instance
  );
  public readonly SaveStateTaxParams = saveStateTaxParams(this.Instance);
  public readonly SendContractorOnboardingLink = sendContractorOnboardingLink(
    this.Instance
  );
  public readonly SetFaviconStatus = setFaviconStatus(this.Instance);
  public readonly SetLogoStatus = setLogoStatus(this.Instance);
  public readonly SendOnboardingLink = sendOnboardingLink(this.Instance);
  public readonly TestCheckCreatedWebhook = testCheckCreatedWebhook(
    this.Instance
  );
  public readonly TestCheckProcessedWebhook = testCheckProcessedWebhook(
    this.Instance
  );
  public readonly TestCheckProcessedLeanWebhook = testCheckProcessedLeanWebhook(
    this.Instance
  );
  public readonly TestContractorPaymentWebhook = testContractorPaymentWebhook(
    this.Instance
  );
  public readonly TestContractorWebhook = testContractorWebhook(this.Instance);
  public readonly TestCreationWebhook = testCreationWebhook(this.Instance);
  public readonly TestEmployeeCheckWebhook = testEmployeeCheckWebhook(
    this.Instance
  );
  public readonly TestEmployeeWebhook = testEmployeeWebhook(this.Instance);
  public readonly TestEmployerOnboardingWebhook = testEmployerOnboardingWebhook(
    this.Instance
  );
  public readonly TestReportWebhook = testReportWebhook(this.Instance);
  public readonly TestEVerifyWebhook = testEVerifyWebhook(this.Instance);
  public readonly TestOnboardingWebhook = testOnboardingWebhook(this.Instance);
  public readonly TestI9Webhook = testI9Webhook(this.Instance);
  public readonly TestShiftWebhook = testShiftWebhook(this.Instance);
  public readonly Token = token(this.Instance);
  public readonly UNSAFE__ForceExpireCookies = UNSAFE__forceExpireCookies(
    this.Instance
  );

  public readonly UploadIDVerification = uploadIDVerification(this.Instance);

  public readonly UpdateAdminInfo = updateAdminInfo(this.Instance);
  public readonly UpdateBankAccount = updateBankAccount(this.Instance);
  public readonly UpdateCompany = updateCompany(this.Instance);
  public readonly UpdateContractor = updateContractor(this.Instance);
  public readonly UpdateCustomerAccount = updateCustomerAccount(this.Instance);
  public readonly UpdateGuestInfo = updateGuestInfo(this.Instance);
  public readonly UpdateEmployeeAsCompany = updateEmployeeAsCompany(
    this.Instance
  );
  public readonly UpdatePartner = updatePartner(this.Instance);
  public readonly UpdateTaxParameters = uploadTaxParameters(this.Instance);
  public readonly UpdateUserInfo = updateUserInfo(this.Instance);
  public readonly CreateCompanyLocation = createCompanyLocation(this.Instance);
  public readonly GetCompanyLocations = getCompanyLocations(this.Instance);
  public readonly UpdateCompanyLocation = updateCompanyLocation(this.Instance);
  public readonly UpdateCompanyVeriffToken = updateCompanyVeriffToken(
    this.Instance
  );
  public readonly GetCompanyVeriffToken = getCompanyVeriffToken(this.Instance);
  public readonly UpdateCompanyTaxDocumentById = updateCompanyTaxDocumentById(
    this.Instance
  );
  public readonly UpdateCheckCreatedWebhook = updateCheckCreatedWebhook(
    this.Instance
  );
  public readonly UpdateCheckProcessedWebhook = updateCheckProcessedWebhook(
    this.Instance
  );
  public readonly UpdateCheckProcessedLeanWebhook =
    updateCheckProcessedLeanWebhook(this.Instance);
  public readonly UpdateContractorPaymentWebhook =
    updateContractorPaymentWebhook(this.Instance);
  public readonly UpdateContractorWebhook = updateContractorWebhook(
    this.Instance
  );
  public readonly UpdateCreationWebhook = updateCreationWebhook(this.Instance);
  public readonly UpdateEmployeeWebhook = updateEmployeeWebhook(this.Instance);
  public readonly UpdateEmployeeCheckWebhook = updateEmployeeCheckWebhook(
    this.Instance
  );
  public readonly UpdateEmployerOnboardingWebhook =
    updateEmployerOnboardingWebhook(this.Instance);
  public readonly UpdateEVerifyWebhook = updateEVerifyWebhook(this.Instance);
  public readonly UpdateOnboardingWebhook = updateOnboardingWebhook(
    this.Instance
  );
  public readonly UpdateI9Webhook = updateI9Webhook(this.Instance);
  public readonly UpdateReportWebhook = updateReportWebhook(this.Instance);
  public readonly UpdateEmployeeCheckByID = updateEmployeeCheckByID(
    this.Instance
  );
  public readonly UpdateShift = updateShift(this.Instance);
  public readonly UpdateShiftWebhook = updateShiftWebhook(this.Instance);
  public readonly UpdateWLInfo = updateWLInfo(this.Instance);
  public readonly UpdateEmployeeAsSelf = updateEmployeeAsSelf(this.Instance);
  public readonly UpdateImageAws = updateImageAws(this.Instance);
  public readonly UpdateFileAws = updateFileAws(this.Instance);
  public readonly Whoami = whoami(this.Instance);
  public readonly GetClosestCheckDate = getClosestCheckDate(this.Instance);
  public readonly GetPayrollRunById = getPayrollRunById(this.Instance);
  public readonly GetPayrollRunEmployees = getPayrollRunEmployees(
    this.Instance
  );
  public readonly GetPayrollRuns = getPayrollRuns(this.Instance);
  public readonly GetPayrollChecks = getPayrollChecks(this.Instance);
  public readonly UpdateCheck = updateCheck(this.Instance);
  public readonly DeleteShift = deleteShift(this.Instance);
  public readonly UpdatePayrollRun = updatePayrollRun(this.Instance);
  public readonly GetCheckPreview = getCheckPreview(this.Instance);
  public readonly PostManageBulkDeductions = postManageBulkDeductions(
    this.Instance
  );
  public readonly FetchCheckDetails = fetchCheckDetails(this.Instance);
  public readonly SubmitShift = submitShift(this.Instance);
  public readonly CreateEmployeeCheck = createEmployeeCheck(this.Instance);
  public readonly GetChecksExistAlert = getChecksExistAlert(this.Instance);
  public readonly CreatePayrollChecks = createPayrollChecks(this.Instance);
  public readonly SubmitPayrollRun = submitPayrollRun(this.Instance);
  public readonly CreatePayrollRun = createPayrollRun(this.Instance);
  public readonly PostParseCheckCSV = postParseChecksCSV(this.Instance);
  public readonly GetReportingPeriodsByFrequency =
    getReportingPeriodsByFrequency(this.Instance);
  public readonly DeletePayrollRun = deletePayrollRun(this.Instance);
  public readonly SetUserPayrollRunSettings = setUserPayrollRunSettings(
    this.Instance
  );
  public readonly ResendFax = resendFax(this.Instance);
  public readonly GetFaxes = getFaxes(this.Instance);
  public readonly GetFormList = getFormList(this.Instance);
  public readonly GetFormTemplate = getFormTemplate(this.Instance);
  public readonly GetFormSubmissions = getFormSubmissions(this.Instance);
  public readonly PostForm = postForm(this.Instance);
  public readonly PostFormRenderPDF = postFormRenderPDF(this.Instance);
  public readonly PostFormRenderJSON = postFormRenderJSON(this.Instance);
  public readonly GetEmployeeRequirementsLinks = getEmployeeRequirementsLinks(
    this.Instance
  );
  public readonly UpdateEmployeeRequirementCreatedWebhook =
    updateEmployeeRequirementCreatedWebhook(this.Instance);

  public readonly TestEmployeeRequirementCreatedWebhook =
    testEmployeeRequirementCreatedWebhook(this.Instance);

  public readonly UpdateEmployeeRequirementUpdatedWebhook =
    updateEmployeeRequirementUpdatedWebhook(this.Instance);

  public readonly TestEmployeeRequirementUpdatedWebhook =
    testEmployeeRequirementUpdatedWebhook(this.Instance);

  public readonly VerifyRoutingNumber = verifyRoutingNumber(this.Instance);
  public readonly CreateBankAccount = createBankAccount(this.Instance);
  public readonly RemoveAccountMembership = removeAccountMembership(
    this.Instance
  );
  public readonly SaveCompanySignature = saveCompanySignature(this.Instance);
  public readonly PostTelemetry = postTelemetry(this.Instance);
  public readonly GetWageViolations = getWageViolations(this.Instance);
  public readonly GetI9OnboardingInfo = getI9OnboardingInfo(this.Instance);
  public readonly UpdateI9OnboardingInfo = updateI9OnboardingInfo(
    this.Instance
  );
  public readonly GetInfoForAuthorizedRepresentative =
    getInfoForAuthorizedRepresentative(this.Instance);
  public readonly UpdateI9OnboardingInfoAsAuthorizedRepresentative =
    updateI9OnboardingInfoAsAuthorizedRepresentative(this.Instance);
  public readonly GetAuthorizedRepresentativeProgress =
    getAuthorizedRepresentativeProgress(this.Instance);
  public readonly GenerateFileGetUrl = generateFileGetUrl(this.Instance);
  public readonly GenerateFilePutUrl = generateFilePutUrl(this.Instance);

  public readonly GetCustomPaperworkTemplates = getCustomPaperworkTemplates(
    this.Instance
  );
  public readonly CreateCustomPaperwork = createCustomPaperwork(this.Instance);
  public readonly FetchCustomPaperwork = fetchCustomPaperwork(this.Instance);
  public readonly UpdateCustomPaperwork = updateCustomPaperwork(this.Instance);
  public readonly UpdateCustomerAccountInfo = updateCustomerAccountInfo(
    this.Instance
  );
  public readonly GetCustomerAccountOnboardingInfo =
    getCustomerAccountOnboardingInfo(this.Instance);
  public readonly ValidateOnboardingToken = validateOnboardingToken(
    this.Instance
  );
  public readonly CreateFundingSource = createFundingSource(this.Instance);

  public readonly GetTransactionEvents = getTransactionEvents(this.Instance);
  public readonly GetWorkerCount = getWorkerCount(this.Instance);

  public readonly GetEmbeddingSignedToken = getEmbeddingSignedToken(
    this.Instance
  );

  public readonly GetPaperworkTemplates = getPaperworkTemplate(this.Instance);
  public readonly PostPaperworkTemplate = postPaperworkTemplate(this.Instance);
  public readonly PutPaperworkTemplate = putPaperworkTemplate(this.Instance);
  public readonly PatchPaperworkTemplate = patchPaperworkTemplate(
    this.Instance
  );
  public readonly ArchivePaperworkTemplate = archivePaperworkTemplate(
    this.Instance
  );
  public readonly MigrateEmployeeData = migrateEmployeeData(this.Instance);
  public readonly MigrateEmployeeTaxParamsData = migrateEmployeeTaxParamsData(
    this.Instance
  );
  public readonly GenerateFilesPutUrl = generateFilesPutUrl(this.Instance);
  public readonly GetCompanyKybDocuments = getCompanyKybDocuments(
    this.Instance
  );
  public readonly PostAttachEVerifyDocumentToWorker =
    postAttachEVerifyDocumentToWorker(this.Instance);
  public readonly GetBulkEVerifyPublicCaseStatuses =
    getBulkEVerifyPublicCaseStatuses(this.Instance);
  public readonly UpdateEVerifyPublicCaseStatus = updateEVerifyPublicCaseStatus(
    this.Instance
  );
  public readonly PostResolveEVerifyPendingReferral =
    postResolveEVerifyPendingReferral(this.Instance);
  public readonly PostResolveEVerifyFinalNonConfirmation =
    postResolveEVerifyFinalNonConfirmation(this.Instance);
  public readonly DownloadEVerifyNotice = downloadEVerifyNotice(this.Instance);
  public readonly GetPendingEVerifyActionByAdmin =
    getPendingEVerifyActionByAdmin(this.Instance);
  public readonly VerifyMicroDeposits = verifyMicroDeposits(this.Instance);
}
