export const PaySchedules = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  semimonthly: "semimonthly",
  monthly: "monthly",
  quarterly: "quarterly",
} as const;

export type PaySchedule = (typeof PaySchedules)[keyof typeof PaySchedules];
