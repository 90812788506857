import { schemes, zSchema } from "@zeal/common";

export const updateBankAccountRequestSchema = zSchema.object({
  account_number: zSchema.string(),
  account_type: zSchema.string(),
  first_name: zSchema.string(),
  is_default: zSchema.boolean().optional(),
  last_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  payment_destination_id: schemes.uuid().optional(),
  routing_number: zSchema.string(),
});

export type TUpdateBankAccountRequestSchema = zSchema.infer<
  typeof updateBankAccountRequestSchema
>;

export const updateBankAccountResponseSchema = zSchema.any();

export type TUpdateBankAccountResponseSchema = zSchema.infer<
  typeof updateBankAccountResponseSchema
>;
