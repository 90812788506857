import {
  EAuthorizedRepresentativeDecision,
  EDocumentKey,
  EPublicI9Status,
} from "@zeal/common";
import { z } from "zod";

export const i9WebhookSchema = z.discriminatedUnion("status", [
  z.object({
    test: z.boolean(),
    companyID: z.string(),
    employeeID: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    status: z.literal(EPublicI9Status.SECTION_1_COMPLETED),
    documents: z.array(z.nativeEnum(EDocumentKey)),
    business_name: z.string(),
  }),
  z.object({
    test: z.boolean(),
    companyID: z.string(),
    employeeID: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    status: z.literal(EPublicI9Status.AUTHORIZED_REPRESENTATIVE_CONTACTED),
    authorized_representative: z.object({
      email: z.string().optional(),
      phone: z.string().optional(),
    }),
    documents: z.array(z.nativeEnum(EDocumentKey)),
    business_name: z.string(),
  }),
  z.object({
    test: z.boolean(),
    companyID: z.string(),
    employeeID: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    status: z.literal(EPublicI9Status.SECTION_2_COMPLETED),
    authorized_representative: z.object({
      first_name: z.string(),
      last_name: z.string(),
      email: z.string().optional(),
      phone: z.string().optional(),
      decision: z.nativeEnum(EAuthorizedRepresentativeDecision),
      decision_made_on: z.string(),
    }),
    documents: z.array(z.nativeEnum(EDocumentKey)),
    business_name: z.string(),
  }),
]);

export const i9WebhookRequestSchema = z.object({
  url: z.string(),
  testMode: z.boolean(),
});

export type TI9WebhookRequest = z.infer<typeof i9WebhookRequestSchema>;
export type TI9WebhookBody = z.infer<typeof i9WebhookSchema>;
