import { TApiResponse } from "../api-utilities/IResponseBase";
import { state_agency } from "./state_agency";

export const TaxTypes = {
  TAX: "TAX",
  PENALTY: "PENALTY",
  INTEREST: "INTEREST",
  STATE: "STATE",
  LOCAL: "LOCAL",
  CITY: "CITY",
  FEE: "FEE",
  ESCROW: "ESCROW",
  ESTIMATED: "ESTIMATED",
  ANNUAL: "ANNUAL",
  EXTENTION: "EXTENTION",
  AUDIT: "AUDIT",
  LOAN: "LOAN",
  UNDER_PROTEST: "UNDER_PROTEST",
  RETURN: "RETURN",
  BILL: "BILL",
  PRIOR_OVERPAYMENT: "PRIOR_OVERPAYMENT",
  CREDIT_MEMO: "CREDIT_MEMO",
  DISOUNT: "DISOUNT",
  LEVY: "LEVY",
  GARNISHMENT: "GARNISHMENT",
  "1": "1", // FEDERAL SS
  "2": "2", // FEDERAL MEDICARE
  "3": "3", // FEDERAL WH
} as const;

export type TXPAmountTypes = (typeof TaxTypes)[keyof typeof TaxTypes];

export enum EBankSpeed {
  STANDARD = "standard",
  SAMEDAY = "sameday",
}

export interface TaxPaymentMeta {
  readonly tin: string;
  readonly payment_code: string;
  readonly tax_period_end_date: string;
  readonly amount_types: TXPAmountTypes[];
  readonly amounts: number[];
  readonly taxpayer_verification: any;
}
export interface IGetCompanyCustodyNodeBalanceRequest {
  readonly odo_user_ids: string[];
}
export interface IGetCompanyCustodyNodeBalance {
  readonly odo_user_id: string;
  readonly balance: string;
  readonly currency: string;
}

export type state_agencies_get_request = {
  readonly zeal_id: string;
};

export type TGetStateAgencyInfoResponse = TApiResponse<state_agency[], "data">;

export type TGetCompanyCustodyNodeBalanceResponse = TApiResponse<
  IGetCompanyCustodyNodeBalance[],
  "odo_users"
>;

export type IGetExternalStatusTransactionRequest = {
  readonly external_id: string;
};

export type TGetStateAgencyTransactionsBulkRequest = {
  readonly odo_user_id: string;
  readonly filter?: string;
};

export type TGetStateAgencyTransactionsBulkResponse = TApiResponse<
  [], // TODO this used to be an array of transactions from the bank partner
  "data"
>;

export type ICreateTransactionData = {
  errors: {
    userErrors: Array<{ input: ICreateTransactionRequest; error: string }>;
    transactionErrors: Array<{
      input: ICreateTransactionRequest;
      error: string;
    }>;
  };
  from_user_id: string;
  transactions: Array<{
    input: ICreateTransactionRequest;
    result: { bankPartnerId: string; transactionRecordId: string };
  }>;
  destinations_and_amounts: IDestinationAndAmount[];
};

export type ICreateTransactionResponse = TApiResponse<
  ICreateTransactionData,
  "data"
>;

export enum payment_creation_types {
  company_tax_penalty = "company_tax_penalty",
  fund_employer_custody = "fund_employer_custody",
  fund_employer_reserve = "fund_employer_reserve",
  fund_tax_node = "fund_tax_node",
  one_day = "one_day",
  one_day_debit = "one_day_debit",
  one_day_tax_transfer = "one_day_tax_transfer",
  pay_employee = "pay_employee",
  pay_state = "pay_state",
  pay_state_from_custody = "pay_state_from_custody",
  prefunded_employee_disbursement = "prefunded_employee_disbursement",
  prefunded_batch_disbursement = "prefunded_batch_disbursement",
  push_deductions = "push_deductions",
  tax_penalty = "tax_penalty",
  two_day_tax_transfer = "two_day_tax_transfer",
  two_day_deductions_transfer = "two_day_deductions_transfer",
  refund_payroll = "refund_payroll",
  refund_reserve_payroll = "refund_reserve_payroll",
  refund_customer_account_payroll = "refund_customer_account_payroll",
  refund_tax = "refund_tax",
}

export const CompanyDebitPaymentTypes = [
  payment_creation_types.fund_employer_custody,
  payment_creation_types.one_day_debit,
];

export interface ICreateTransactionRequest {
  readonly from_user: string;
  readonly to_user?: string;
  readonly zeal_id?: string;
  readonly check_id?: string;
  readonly amount?: number;
  readonly employer_taxes?: number;
  readonly destinations_and_amounts?: IDestinationAndAmount[];
  readonly company_name?: string;
  readonly partner_name?: string;
  readonly partner_id?: string;
  readonly net_amount?: number;
  readonly extra_meta?: TaxPaymentMeta;
  readonly note?: string;
  readonly type_of_payment?: payment_creation_types;
  readonly same_day?: boolean;
  readonly batch_number?: number;
  readonly enable_idempotency?: boolean;
  readonly company_id?: string;
  readonly addenda?: string | string[];
}

export interface IDestinationAndAmount {
  readonly to_user_id: string;
  readonly zeal_id: string;
  readonly net_amount: number;
  readonly paystub_id: string;
}

export interface IMakeTransactionResponse {
  readonly input: IMakeTransactionInput;
  readonly success: boolean;
  readonly result: IMakeTransactionResult;
}

export interface IMakeTransactionInput {
  transaction_group_id: string;
  transaction_record_id: string;
  from_odo_id: string;
  from_account_id: string;
  to_odo_id: string;
  to_account_id: string;
  amount: number;
  addenda: string[];
  speed: EBankSpeed;
  metadata: IMakeTransactionMetadata;
}

export interface IMakeTransactionMetadata {
  recordId: string;
  note?: string;
}

export interface IMakeTransactionResult {
  transactionRecordId: string;
  bankPartnerId: string;
}
