import { z } from "zod";

import { EGovernmentPhotoIDType, UserAccountType } from "../accounts";
import { EKYCStatusList } from "../kyc/EKYCStatus";
import { EEmploymentStatus } from "../workers-shared/EEmploymentStatus";
import { PaySchedules } from "./PaySchedule";

export const ZWorker = z.object({
  user_type: z
    .nativeEnum(UserAccountType)
    .default(UserAccountType.CompanyWorker),
  emailNotConfirmed: z.boolean().optional(),
  salt: z.string().optional(),
  hash: z.string().optional(),

  // Worker profile info
  email: z.string().toLowerCase(),
  first_name: z.string(),
  last_name: z.string(),
  middle_initial: z.string().optional(),
  phone_number: z.string().optional(),
  dob: z.string().optional(),
  ssn: z.string().optional(),
  address: z.string().optional(),
  address_line2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),

  // Worker employment settings
  title: z.string().optional(),
  default_pay_schedule: z
    .enum([
      PaySchedules.daily,
      PaySchedules.weekly,
      PaySchedules.biweekly,
      PaySchedules.semimonthly,
      PaySchedules.monthly,
      PaySchedules.quarterly,
    ])
    .default(PaySchedules.weekly),
  start_date: z.string().optional(),
  work_week_start: z.string().optional(),
  employment_status: z
    .nativeEnum(EEmploymentStatus)
    .default(EEmploymentStatus.LIVE),
  onboarded: z.boolean().default(false),
  onboardedAt: z.string().optional(),
  taxengine_workerID: z.string(),
  working_state: z.string().optional(),
  workLocationID: z.string().optional(),
  organization: z.string().optional(),
  is_943: z.boolean().default(false),
  is_scheduleH: z.boolean().default(false),
  default_wage: z.number().optional(),
  default_ot_wage: z.number().optional(),
  default_dt_wage: z.number().optional(),
  salary: z.number().optional(),
  salary_firstDate: z.string().optional(),
  is_regular: z.boolean().default(false),
  is_salary: z.boolean().default(false),
  term_date: z.string().optional(),
  external_id: z.string().optional(),

  // TBD
  paychex_workerID: z.string().optional(),
  autopilot_on: z.boolean().default(false),
  class_code: z.number().optional(),
  workAddress: z.string().optional(),
  smarty_streets_info: z.any().optional(),
  tax_info: z.any().optional().default({}),
  state_tax_info: z.any().optional().default({}),
  deposit_one: z.any().optional().default({}),
  deposit_two: z.any().optional().default({}),
  hellosign_company_sigId: z.string().optional(),
  hellosign_employee_sigId: z.string().optional(),
  hellosign_sig_req_id: z.string().optional(),
  complete_paperwork: z.boolean().optional().default(false),
  veriff_id: z.string().optional(),
  veriff_stage: z.string().optional(),
  veriff_images: z.any().optional(),
  doc_type: z.string().optional(),
  governmentPhotoIDType: z.nativeEnum(EGovernmentPhotoIDType).optional(),
  ss_card: z.string().optional(),
  payroll_results: z.any().optional().default([]),
  pto: z.any().optional().default([]),
  vac: z.any().optional().default([]),
  stage: z.string().optional(),
  ocw_payeeID: z.string().optional(),
  companyID: z.string().optional(),
  odo_user_id: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  onboard_docs_faxed: z.boolean().default(false),
  metadata: z.any().default({}),
  business_ein: z.string().optional(),
  paperless_w2: z.boolean().optional(),

  kyc_status: z.enum(EKYCStatusList).optional(),
  kyc_watchlists: z.array(z.string()),
  kyc_pending_review: z.boolean().optional(),
});

export type TWorker = z.infer<typeof ZWorker>;
