import { z } from "zod";
import { uuid } from "../../schema-validation/schemes";

export const taskSchema = z.object({
  taskId: uuid(),
  taskDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  productDefinitionId: uuid(),
  companyID: uuid(),
  status: z.string().default("pending"),
  fields: z.object({}).default({}),
});

export type ZTask = z.infer<typeof taskSchema>;
