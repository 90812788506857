import { z } from "zod";
import { uuid } from "../../schema-validation/schemes";

export const taskDefinitionTemplateSchema = z.object({
  taskDefinitionTemplateId: uuid(),
  productDefinitionId: uuid(),
  requirements: z.object({
    employee: z.array(
      z.object({
        source: z.string(),
        fields: z.array(z.string()),
      })
    ),
  }),
});

export type ZTaskDefinitionTemplate = z.infer<
  typeof taskDefinitionTemplateSchema
>;
